import type { SerializedStyles, Theme } from "@emotion/react";
import { useTheme } from "@emotion/react";
import type { AviaryAccents } from "aviary-tokens";
import { colorProfileMapper, type AviaryColors } from "aviary-tokens";
import type { HTMLProps, ReactNode } from "react";

import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import type { AviarySize } from "@aviary/types";

import { DeprecatedBox } from "./DeprecatedBox";

import * as styles from "./Box.styles";

type BoxColors = Extract<
  AviaryColors,
  "success" | "system" | "highlight" | "danger" | "warning" | "info"
>;

interface BoxProps extends HTMLProps<HTMLDivElement> {
  /**
   * Allows you to add color to the background
   *
   * @default null
   */
  isColor?: BoxColors;
  /**
   * Allows you to color the box with an accent color
   *
   * @default null
   */
  accentColor?: AviaryAccents;
  /**
   * Removes gutters made by padding from the Container component. Uses negative margin.
   *
   * @default false
   */
  isGutterless?: boolean;
  /**
   * Allows you to add color to the border
   *
   * !important ONLY Used with the DS3 version of the Box
   *
   * @default false
   */
  isBordered?: boolean;
  /**
   * Customize the spacing (padding) of the Box
   *
   * @default normal
   */
  padding?: AviarySize;
  /*
   * Allows you to pass custom styles, use sparingly!
   */
  layoutStyles?:
    | SerializedStyles
    | (SerializedStyles | ((theme: Theme) => SerializedStyles))[]
    | ((theme: Theme) => SerializedStyles);
  /**
   * Children to be rendered within the Box
   *
   * @default undefined
   */
  children?: ReactNode | ReactNode[];
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/layout/Box
 */
const Box = ({
  isColor = null,
  isGutterless = false,
  padding = "normal",
  isBordered = false,
  ...props
}: BoxProps) => {
  const isDS4Enabled = useIsDS4Enabled();
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);

  if (!isDS4Enabled)
    return <DeprecatedBox {...{ isColor, isGutterless, padding, isBordered, ...props }} />;

  const { children, layoutStyles, accentColor, ...rest } = props;

  const boxStyles = [
    styles.box,
    styles.padding[padding],
    isGutterless && styles.gutterless,
    isColor && styles.themedColorStyles(themeColors[isColor]),
    accentColor && styles.accentColor[accentColor],
  ];

  return (
    <section css={[boxStyles, layoutStyles]} {...rest}>
      {children}
    </section>
  );
};

export { Box, type BoxColors, type BoxProps };
