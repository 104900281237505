import { useTheme } from "@emotion/react";
import { colorProfileMapper } from "aviary-tokens";

import type { BoxProps } from "../Box";

import * as styles from "./DeprecatedBox.styles";

const DeprecatedBox = ({
  isColor,
  isGutterless,
  isBordered,
  children,
  padding,
  layoutStyles,
  ...rest
}: BoxProps) => {
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);

  const css = [
    styles.box,
    styles.padding[padding],
    isGutterless && styles.gutterless,
    isColor && styles.themedColorStyles(themeColors[isColor]),
    isColor === "system" && styles.system,
    isBordered && isColor && styles.borderColor(themeColors[isColor]),
  ];

  return (
    <section css={[css, layoutStyles]} {...rest}>
      {children}
    </section>
  );
};

export { DeprecatedBox };
